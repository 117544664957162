@import 'src/styles/colors';

.threeDots {
  &:after {
    content: "\22EF";
    font-size: 30px;
    color: $black;
    height: 100%;
  }
}

.dropdown-item {
  &:last-child {
    color: $red;
    &:hover {
      color: $red;
    }
  }
}