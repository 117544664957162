.login-page {
    svg {
        width: 200px;
        margin: 0 auto 40px;
        display: block;
    }
    .card {
        margin-top: 40px;
    }
    .card-body {
        padding: 20px 40px 40px;
        width: 400px;
    }
}