@import "src/styles/colors";


#main-nav-container {
  width: 100%;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  box-shadow: 0 2px 24px rgba(0,0,0,0.1);
  margin-bottom: 40px;
  border-top: 6px solid #53c154;
}

.logo {
  width: 100px;
  position: absolute;
  left: 68px;
  top: 15px;
}

.utility-nav-toggle {
  position: absolute;
  right: 20px;
  top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    .nav-toggle {
      color: #009bf3;
    }
  }
  .nav-toggle {
    &:hover {
      color: #009bf3;
    }
  }
  span {
    margin-right: 8px;
  }
}
.active-link {
  color: #009bf3 !important;
}

.header-link {
  color: $black;
  &:hover {
    color: #009bf3;
  }
}

.btn.btn-primary.app-menu-toggle {
  position: absolute;
  left: 20px;
  top: 22px;
  background-color: #fff !important;
  //border: 1px solid #ddd !important;
  border: none !important;
  display: flex;
  padding: 8px;
  color: $black;
  &:hover {
    cursor: pointer;
    background-color: #f1f1f5 !important;
  }
  &:active, &.active {
    background-color: #f1f1f5 !important;
    //border: 1px solid #009bf3 !important;
    color: #009bf3 !important;
  }
  svg {
    width: 24px;
    height: 24px;
  }
}

.app-menu {
  min-width: 200px;
  position: absolute;
  z-index: 99;
  top: 70px;
  left: 20px;
  background-color: $white;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 2px 24px rgba(0,0,0,0.1);
  padding: 0;
  h2 {
    font-size: 14px;
    padding: 8px 16px;
    margin: 0;
    font-weight: 800;
    background: #f1f1f1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-transform: uppercase;
  }
  a.app-menu-item {
    padding: 12px 16px;
    color: $black;
    text-decoration: none;
    display: block;
    border-radius: 8px;
    border: 4px solid #fff;
    &:hover {
      background: #f1f1f1;
      cursor: pointer;
    }
    svg {
      margin-right: 8px;
    }
  }

}

.utility-menu-container {
  display: block;
  // width: 100vw;
  // height: 100vh;
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: 100;
}

.utility-menu {
  min-width: 200px;
  position: absolute;
  z-index: 99;
  top: 70px;
  right: 20px;
  background-color: $white;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 2px 24px rgba(0,0,0,0.1);
  padding: 0;
  a.utility-menu-item {
    padding: 20px;
    color: $black;
    text-decoration: none;
    display: block;
    &:hover {
      color: #009bf3;
      cursor: pointer;
    }
  }

}

.utility-menu-backdrop {
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
}