@import "./styles/colors";


html {
  background-color: #dbe0e4;
}

body {
  background-color: transparent !important;
}
// #root {
//   height: 100vh;
//   background-color: $lightGray;
// }

.h-40 {
  height: 40px !important;
}

.h-32 {
  height: 32px !important;
}
.w-12 {
  width: 12px !important;
}

.w-15 {
  width: 15px !important;
}

.w-24 {
  width: 24px !important;
}

.w-40 {
  width: 40px !important;
}

.w-small {
  width: 76px !important;
}

.w-medium {
  width: 176px !important;
}

.w-large {
  width: 276px !important;
}

.w-huge {
  width: 376px !important;
}

.border-light-gray {
  border-color: $lightGray;
}

.background-light-gray {
  background-color: $lightGray;
}

.cursor-pointer {
  cursor: pointer;
}

.active-button {
  background: $black;
  color: $white;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.4;
}

.hover-pale-blue {
  &:hover {
    background-color: $paleBlue;
  }
}

#root {
  .card {
    border: none;
    box-shadow: 0 2px 24px rgba(0,0,0,0.1);
    border-radius: 12px;
  }
  
  .btn {
    &.btn-primary {
      background-color: #009bf3;
      border: 1px solid #009bf3;
      &:hover, &:active, &:focus {
        background-color: #0068ba;
        border: 1px solid #0068ba;
      }
    }
    svg.bi-plus {
      width: 24px;
      height: 24px;
      margin-left: -6px;
    }
  }


}
