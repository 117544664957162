@import 'src/styles/colors';

.custom-checkbox {
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 1;
  opacity: 0;
}

.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox+label::before {
  content: '';
  width: 24px;
  height: 24px;
  border: 1px solid $black;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
}

.custom-checkbox:checked+label::before {
  background-image: url("../../../assets/checkbox.png");
}

.custom-checkbox:disabled+label::before {
  background-color: $ultraLightGray;
  border-color: $gray;
}