#pagination {
    margin: 20px 0 32px;
    .btn {
        margin: 0 2px;
        &.active-button {
            background-color: #009bf3;
            border: 1px solid #009bf3;
            color: #fff;
        }
    }
}