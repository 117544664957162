@import 'src/styles/colors';

.error {
  color: $red;
  font-size: 12px;
  margin: 0;
}

.auto-generate {
  width: auto;
  color: #009bf3;
  margin-left: 0;
}