$black: #000000;
$white: #ffffff;
$darkGrey: rgba(0, 0, 0, 0.56);
$gray: #E0E0E0;
$lightGray: rgba(0, 0, 0, 0.16);
$extraLightGray: rgba(0, 0, 0, 0.08);
$ultraLightGray: rgba(0, 0, 0, 0.04);
$paleBlue: #dcf2ff;
$blue: #2962FF;
$red: #FF0000;

