.rbt-close-content {
  display: none !important;
}

.dropdown-item {
  &:last-child {
    color: inherit !important;
    &:hover {
      color: inherit !important;
    }
  }
}

.dropdown-item.active, .dropdown-item:active {
  background-color: inherit !important;
}