@import 'src/styles/colors';

.switcher{
  display: flex;
  position: relative;
  cursor: pointer;
  justify-content: space-between;
  height: 40px;
  width: 100px;
  color: $black;
  border-radius: 8px;
  border: 2px solid $gray;
  div {
    width: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .checked {
    background-color: $black;
    color: $white;
    border-radius: 8px;
    width: 60px;
    height: 40px;
    margin-top: -2px;

  }
}