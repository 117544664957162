@import 'src/styles/colors';

th {
  font-size: 14px;
  &:nth-child(2), &:nth-child(3) {
    min-width: 160px;
  }
  &:last-child {
    div {
      justify-content: center !important;
    }
  }
}

.head-row {
  background-color: $ultraLightGray;
}

.headCell {
  &:hover {
    background-color: $gray;
  }
}