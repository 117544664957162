@import "../../../styles/colors";

.ant-select-dropdown {
  z-index: 2000;
  //border: 2px solid $black!important;
}

.ant-select-arrow {
  display: none!important;
}

.ant-select-item-option {
  height: 40px;
  display: flex;
  align-items: center;
}

// .ant-select-item-option-active, .ant-select-item-option-selected {
//   //background-color: $black!important;
//   //color: $white!important;
// }

.ant-select-selection-item-remove {
  margin-top: -4px;
  color: $black;
}

.modal-body{
  .ant-select {
    .ant-select-selector {
      height: 40px;
      //border: 2px solid $black;
      //padding-left: 40px;
      .ant-select-selection-overflow {
        .ant-select-selection-overflow-item {
          .ant-select-selection-item {
            margin-top: 0;
            margin-bottom: 0;
            //height: 32px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.ant-select-selection-overflow {
  .ant-select-selection-overflow-item {
    .ant-select-selection-item {
      background-color: #eee;
    }
  }
}

.search-icon {
  position: absolute;
  bottom: 5px;
  left: 5px;
}